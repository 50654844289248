<template>
  <div class="header">
    <div class="header--nav">
      <img src="../assets/images/Screenshot-2021-07-26-at-18.04.39.png" loading="lazy" width="110"
           alt="ArtekApp logo" class="brand">
      <img
        src="../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
        loading="lazy" alt="Download ArtekApp on the app store" class="store">
    </div>
  </div>
  <div class="content" v-if="artwork">
    <div class="item--wrapper">
      <div class="item--image">
        <div class="item--image-wrapper">
          <img :src="`https://api.artek.app/images/art/${artwork.imageModelUid}.jpg`"
               loading="lazy" alt="Artwork image" class="image-3">
          <div class="item--image-owner">
            <div class="item--image-owner-image"
                 :style="{ 'background-image': `url(${artwork.owner.profileImage})` }"></div>
            <div class="item--image-owner-detail">
              <div class="owner--handle">{{ artwork.owner.handle }}</div>
              <!--
              <div class="owner--location">
                Ben Brown Fine Arts
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
      <div class="item--detail">
        <div class="item--detail-content">
          <div class="artwork--title">{{ artwork.title }}</div>
          <div class="artwork--artist">{{ artwork.artist }}</div>
          <div class="artwork--medium">{{ artwork.medium }}</div>
          <div class="artwork--size">
            {{ +((artwork.width / 2.54).toFixed(2)) }} x
            {{ +((artwork.height / 2.54).toFixed(2)) }} in.
          </div>
          <div class="artwork--size-cm">
            {{ +(artwork.width.toFixed(2)) }} x
            {{ +(artwork.height.toFixed(2)) }} cm
          </div>
          <div class="artwork--description">
            {{ artwork.caption }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="contact">Get in touch with us via email <a href="#">info@artek.app</a><br></div>
    <div class="divider"></div>
    <div class="footer--nav">
      <div class="footer--address">
        Artekapp is registered at Kemp House, 160 City Road, London, United Kingdom, EC1V 2NX
        Company number 13171302<br></div>
      <div class="footer--nav-links">
        <a href="#" class="footer-link end">Privacy Policy</a>
        <a href="#" class="footer-link">Terms of Use</a>
        <a href="#" class="footer-link">Legal</a>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'Home',
  data() {
    return {
      artwork: null,
    };
  },
  components: {},
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const artwork = params.get('a');
    axios.get(`https://api.artek.app/api/shared/art/${artwork}`)
      .then((response) => {
        this.artwork = response.data;
      });
  },
};
</script>
